<template>
  <div>
    <div class="main-title">我的数据</div>
    <div v-loading="countLoading">
      <div class="margin-sm">
        <el-select
          v-if="role == 1 || role == 8"
          v-model="userId"
          placeholder="请选择用户"
          size="mini"
          style="width: 150px"
        >
          <el-option
            v-for="item in selectData"
            :key="item.user_id"
            :label="item.name"
            :value="item.user_id"
          >
          </el-option>
        </el-select>

        &nbsp;&nbsp;&nbsp;&nbsp;

        <el-date-picker
          v-model="datePick"
          end-placeholder="结束日期"
          range-separator="至"
          size="mini"
          start-placeholder="开始日期"
          type="daterange"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <el-button size="mini" type="primary" @click="getPunchData">
          筛选
        </el-button>
      </div>
      <div class="content-list-page page-content">
        <div
          v-for="item in tableData"
          :key="item.id"
          class="list-head"
          style="position: relative; margin-bottom: 10px"
        >
          <el-row :gutter="5">
            <el-col :span="10">
              <div class="text-center text-gray">
                流量组员
                <el-tooltip
                  class="item"
                  content="组员名称"
                  effect="dark"
                  placement="top"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="cursor: pointer"
                  ></i>
                </el-tooltip>
              </div>
              <div class="text-center padding-t">{{ item.name }}</div>
            </el-col>
            <el-col :span="10">
              <div class="text-center text-gray">
                微信添加总数
                <el-tooltip
                  class="item"
                  content="一共添加的微信数量"
                  effect="dark"
                  placement="top"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="cursor: pointer"
                  ></i>
                </el-tooltip>
              </div>

              <div class="text-center padding-t">
                <el-popover placement="bottom" trigger="click" width="400">
                  <div style="max-height: 40vh; overflow: auto">
                    <el-table :data="item.creator_list">
                      <el-table-column
                        align="center"
                        label="录入用户名称"
                        property="name"
                      />
                      <el-table-column
                        align="center"
                        label="录入的数量"
                        property="count"
                      />
                    </el-table>
                  </div>
                    <el-link slot="reference" type="primary" underline>{{ item.count }}</el-link>
                </el-popover>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "List",
  data() {
    return {
      tableData: [],
      datePick: "",
      userId: "",
      countLoading: false,
      selectData: [],
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
    }),
  },
  methods: {
    ...mapActions("flow", ["myWxData", "selectMembers"]),
    getPunchData() {
      this.init();
    },
    async getNormalUser() {
      const { data } = await this.selectMembers();
      this.selectData = data;
    },
    async init() {
      this.countLoading = true;
      let form = {
        datePick: this.datePick,
        userId: this.userId,
      };
      const { data } = await this.myWxData(form);
      this.tableData = data;
      if (this.role == 1 || this.role == 8) {
        this.getNormalUser();
      }

      this.countLoading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.selectPic {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.selectPic p {
  float: left;
  width: 20%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.selectPic p:first-child {
  cursor: default;
}
</style>